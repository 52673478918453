/* eslint-disable no-console */
import { addYears } from "date-fns";
import { useEffect } from "react";
import { SCHEDULING_LINKS_ICON_ORDER } from "../components/forms/scheduling-links/SchedulingLinkForm.util";
import { EventColor } from "../reclaim-api/EventMetaTypes";
import {
  DelayStart as DelayStartDto,
  SchedulingPriority,
} from "../reclaim-api/scheduling-links/scheduling-links-client";
import { DelayStart, SchedulingLink } from "../reclaim-api/scheduling-links/SchedulingLinks";
import { MembershipRole, RequestedTeamStatus, TeamMember, TeamMemberViewStatus } from "../reclaim-api/team/Team";
import { ReclaimEdition, ThinPerson } from "../reclaim-api/Users";
import { MOCK_EMAIL_DOMAINS, MOCK_FIRST_NAMES, MOCK_LAST_NAMES } from "./dev.mock";
import { isEven } from "./numbers";

const OrigDate = Date;

/* Time-Travel */

// Reset global Date
export function unmockDate() {
  if (!Date["mocked"]) return;
  Date = OrigDate;
}

export function mockDate(offset: number) {
  // Reset mock if offset changed
  if (!!Date["mocked"]) {
    if (offset === Date["offset"]) return;
    unmockDate();
  }

  // Replace global Date w/ Proxy
  Date = new Proxy(OrigDate, {
    // offset new Date()
    construct: function (Target, args: Parameters<DateConstructor>): Date {
      // props to https://stackoverflow.com/a/43160428/671457
      if (args.length === 0) return new Target(OrigDate.now() - offset);
      return new Target(...args);
    },

    get: function (Target, prop, receiver) {
      // used to detect mocked Date
      if (prop === "mocked") return true;
      if (prop === "offset") return offset;

      // offset Date.now()
      if (prop === "now") {
        return () => new Target(OrigDate.now() - offset).getTime();
      }

      return Reflect.get(Target, prop, receiver);
    },
  });
}

export function useAnnounceMount(name: string, ...data: unknown[]): void {
  function getArgs(action: string): unknown[] {
    const args: unknown[] = [`${name} ${action}`];
    if (data) args.push(...data);
    return args;
  }

  useEffect(() => {
    console.log(...getArgs("mounted"));
    return () => console.log(...getArgs("unmounted"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const hashNum = (seed: unknown): number => {
  const str = JSON.stringify(seed);
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  let [a, b, c, d] = [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];

  let t = (a + b) | 0;
  a = b ^ (b >>> 9);
  b = (c + (c << 3)) | 0;
  c = (c << 21) | (c >>> 11);
  d = (d + 1) | 0;
  t = (t + d) | 0;
  c = (c + t) | 0;
  return (t >>> 0) / 4294967296;
};

export const hashNumBetween = (seed: unknown, min: number, max: number): number => hashNum(seed) * (max - min) + min;
export const hashIntBetween = (seed: unknown, min: number, max: number): number =>
  Math.round(hashNum(seed) * (max - min) + min);

export const hashHexColor = (seed: unknown): string => {
  const hash = hashIntBetween(seed, 0, 1000);
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return ("#" + "00000".substring(0, 6 - c.length) + c) as string;
};

export const hashDateBetween = (seed: unknown, min: Date, max: Date): Date => {
  const minMs = min.getTime();
  const maxMs = max.getTime();
  const num = hashIntBetween(seed, minMs, maxMs);
  return new Date(num);
};

export const hashInArray = <T>(seed: unknown, arr: readonly T[]): T => arr[hashIntBetween(seed, 0, arr.length - 1)];
export const hashEventColor = (seed: unknown) => hashInArray(seed, EventColor.all);

export const hashEmailDomain = (seed: unknown) => hashInArray(seed, MOCK_EMAIL_DOMAINS);
export const hashFirstName = (seed: unknown) => hashInArray(seed, MOCK_FIRST_NAMES);
export const hashLastName = (seed: unknown) => hashInArray(seed, MOCK_LAST_NAMES);

export const hashEmail = (seed: unknown, base = `${hashFirstName(seed)}.${hashLastName(seed)}}`) =>
  `${base}@${hashEmailDomain(seed)}`;

export const hashInObjVals = <T extends {}>(seed: unknown, obj: T): T[keyof T] =>
  obj[hashInArray(seed, (Object.keys(obj) as (keyof T)[]).sort())];

export const hashHexColorFromEventColors = (value: unknown) => {
  const eventColor = hashEventColor(value);

  switch (eventColor.key) {
    case "AUTO":
    case "NONE":
      return hashHexColor(value);
    default:
      return eventColor.hex;
  }
};

export const hashBoolean = (seed: unknown): boolean => isEven(hashIntBetween(seed, 0, 100));

export const hashOrUndefined = <T>(seed: unknown, elseVal: T): T | undefined =>
  hashBoolean(seed) ? elseVal : undefined;
export const hashOrNull = <T>(seed: unknown, elseVal: T): T | null => (hashBoolean(seed) ? elseVal : null);

export const hashAvatarUrl = (
  seed: unknown,
  { size, set, bg }: { size?: number; set?: 1 | 2 | 3 | 4 | 5; bg?: 1 | 2 } = { size: 200, set: 1, bg: 1 }
): string => `https://robohash.org/${hashIntBetween(seed, 0, 10000)}?set=set${set}&bgset=bg${bg}&size=${size}x${size}`;

export const hashThinPerson = (seed: unknown, fill: Partial<ThinPerson> = {}): ThinPerson => {
  const baseHash = `${hashNum(seed)}`;
  const firstName = hashFirstName(`${baseHash}-firstName`);
  const lastName = hashLastName(`${baseHash}-lastName`);

  return {
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    avatarUrl: hashAvatarUrl(`${baseHash}-avatarUrl`),
    email: hashEmail(`${baseHash}-email`, `${firstName}.${lastName}`),
    ...fill,
  };
};

export const hashTeamMember = (seed: unknown, now: Date, fill: Partial<TeamMember> = {}): TeamMember => {
  const baseHash = `${hashNum(seed)}`;
  const firstName = hashFirstName(`${baseHash}-firstName`);
  const lastName = hashLastName(`${baseHash}-lastName`);

  return {
    avatarUrl: hashAvatarUrl(`${baseHash}-avatarUrl`),
    createdAt: hashDateBetween(`${baseHash}-createdAt`, addYears(now, -2), now).toISOString(),
    daysLeftInTrial: hashIntBetween(`${baseHash}-daysLeftInTrial`, -1, 30),
    edition: hashInArray(`${baseHash}-edition`, ReclaimEdition.all),
    editionAfterTrial: hashInArray(`${baseHash}-editionAfterTrial`, ReclaimEdition.all),
    email: hashEmail(`${baseHash}-email`, `${firstName}.${lastName}`),
    invitationId: `${hashIntBetween(`${baseHash}-invitationId`, 0, 1000)}`,
    inviteUrl: `${typeof window === "undefined" ? "" : window.location.origin}`,
    membershipRole: hashInObjVals(`${baseHash}-membershipRole`, MembershipRole),
    personalizedInviteMessage: "",
    requestedTeamId: hashIntBetween(`${baseHash}-requestedTeamId`, 0, 1000),
    status: hashInObjVals(`${baseHash}-status`, TeamMemberViewStatus),
    teamMemberId: {
      teamId: hashIntBetween(`${baseHash}-teamId`, 0, 1000),
      userId: `${hashIntBetween(`${baseHash}-userId`, 0, 1000)}`,
    },
    editionUsage: hashOrUndefined(
      `${baseHash}-editionUsage`,
      hashInArray(`${baseHash}-editionUsage`, ReclaimEdition.all)
    ),
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    paidSeat: hashBoolean(`${baseHash}-paidSeat`),
    requestedTeamStatus: hashOrUndefined(
      `${baseHash}-requestedTeamStatus`,
      hashInObjVals(baseHash, RequestedTeamStatus)
    ),
    ...fill,
  };
};

export const hashSchedulingLink = (seed: unknown, fill: Partial<SchedulingLink> = {}): SchedulingLink => {
  const baseHash = `${hashNum(seed)}`;

  return {
    durations: [15, 30, 45],
    enabled: hashBoolean(`${baseHash}-enabled`),
    iconType: hashInArray(`${baseHash}-iconType`, SCHEDULING_LINKS_ICON_ORDER),
    id: `${hashNum(`${baseHash}-id`)}`,
    organizer: hashThinPerson(`${baseHash}-organizer`),
    priority: hashInArray(`${baseHash}-priority`, [SchedulingPriority.DEFAULT, SchedulingPriority.HIGH]),
    slug: hashFirstName(`${baseHash}-firstName`),
    timePolicyType: hashInArray(`${baseHash}-timePolicyType`, ["WORK", "PERSONAL", "ONE_OFF", "MEETING"]),
    title: "Scheduling Link Title",
    startDate: undefined,
    endDate: undefined,
    hidden: hashBoolean(`${baseHash}-hidden`),
    delayStart: hashInArray(`${baseHash}-delayStart`, Object.keys(DelayStartDto) as DelayStart[]),
    ...fill,
  };
};
